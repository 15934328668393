import React from "react";
import { useTranslation } from "react-i18next";
const Dir = () => {
    const [t, i18n] = useTranslation();

    return (
        <>
            <div className="op op-direction">
                <button
                    id="ltr"
                    // className="active"
                    onClick={() => {
                        document.body.dir = "ltr";
                        i18n.changeLanguage("en");
                    }}
                >
                    LTR
                </button>
                <button
                    id="rtl"
                    onClick={() => {
                        document.body.dir = "rtl";
                        i18n.changeLanguage("ar");
                    }}
                >
                    RTL
                </button>
            </div>
        </>
    );
};
export default Dir;
