import EventHome from "./Event-home";

const EventPage = () => {
    return (
        <>
            <div id="event-page"></div>
            <div className="event-page">
                <div className="right arrow">
                    <p> {">"} </p>
                </div>
                <div className="left arrow">
                    <p> {"<"} </p>
                </div>
                <div className="container">
                    <EventHome />
                </div>
            </div>
        </>
    );
};
export default EventPage;
