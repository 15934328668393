/* 
    TODO:* search how to create slid bar for complete design
    TODO:* fix-color for toggle menu
    TODO: Add LocalLang File By next18.js
*/

import React, { useEffect } from "react";
import { ClassToGroups } from "./Functions/TargetElement";
import { BrowserRouter, Routes, Route } from "react-router-dom";
/* Start Include Components  */
import SettingBox from "./Components/Setting/Setting_Box";
import Loader from "./Components/Loader/Loader";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Event from "./Components/Event/Event";
/* End Include Components  */

/* Start Include Pages */
import EventPage from "./Pages/Events/Events_Page";
/* End Include Pages */

/* Start Include Style Pages */
import "./Pages/Events/Events_Page.css";
/* End Include Style Pages */

/* Start Include Assets Files */
import favicon from "./assets/images/favicon/favicon.svg";
/* End Include Assets Files */

import "animate.css";
import "./Colors.css";
import "./Style.css";
/* include style rtl */
import "./Dir/Dir_RTL.css";
/* include style file */
import "./theme/Orange.css";
import "./theme/Dark-blue.css";
import "./theme/Dark.css";
import "./theme/Light.css";
/* Start Set Animate For Elements */
/* Set Animate By Using ClassToGroups Function */
const FadeInDown = [".f-i-Down"];
const FadeInUp = [".f-i-up"];
const FadeInRight = [".f-i-right"];
const FadeInLeft = [".f-i-left"];
ClassToGroups("animate__fadeInDown", FadeInDown);
ClassToGroups("animate__fadeInUp", FadeInUp);
ClassToGroups("animate__fadeInRight", FadeInRight);
ClassToGroups("animate__fadeInLeft", FadeInLeft);
/* End Set Animate For Elements */
const App = () => {
    const documentData = {
        title: "AWDS Company",
        favicon: favicon,
        description: "Welcome To AWDS Compony",
        author: "AWDS",
        direction: "ltr",
    };

    useEffect(() => {
        document.title = documentData.title;
        document.querySelector("head [rel='icon']").href = documentData.favicon;
        document.querySelector("head [name='description']").content =
            documentData.description;
        document.body.dir = documentData.direction;
    });

    return (
        <BrowserRouter>
            <div className="App ">
                <Header Logo="Logo" LogoTo="/" />
                <Loader />
                <SettingBox />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <Home />
                                <Event />
                            </>
                        }
                    />

                    <Route path="/event" element={<EventPage />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
};

export default App;
