// import { TargetElement } from "./../../Functions/TargetElement";
import Icon01 from "./../../assets/images/svg/brifecase-timer.svg";
import Icon02 from "./../../assets/images/svg/award.svg";

// social-media icons with arrow down
import Facebook from "./../../assets/images/svg/facebook.svg";
import Instagram from "./../../assets/images/svg/instagram.svg";
import Twitter from "./../../assets/images/svg/twitter.svg";
import arrowDown from "./../../assets/images/svg/angle-down.svg";

const Home = () => {
    const data = {
        heading: "WELCOME TO AWDS",
        description:
            "BEST COMPANY FOR CREATE YOUR WEBSITE BY PROFESSIONAL TEEMS  WITH USING NEW TECHNOLOGY",
        boxText: "BEST COMPANY FOR  CREATE YOUR  WEBSITE",
    };
    const box = [
        {
            key: 1,
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="82"
                    height="82"
                    viewBox="0 0 82 82"
                >
                    <path
                        d="M27.333,13.667A13.667,13.667,0,1,1,13.667,0,13.667,13.667,0,0,1,27.333,13.667Z"
                        transform="translate(27.333 34.85)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M4.271,0V3.178A3.383,3.383,0,0,1,2.6,6.116L0,7.688"
                        transform="translate(37.583 44.246)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M19.649,54.667H46.982c13.735,0,16.2-5.5,16.913-12.2l2.563-27.333C67.379,6.8,64.988,0,50.4,0H16.232C1.643,0-.749,6.8.174,15.136L2.736,42.469C3.454,49.166,5.914,54.667,19.649,54.667Z"
                        transform="translate(7.685 20.5)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M0,13.667V10.933C0,4.886,0,0,10.933,0H16.4C27.333,0,27.333,4.886,27.333,10.933v2.733"
                        transform="translate(27.333 6.833)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M19.27,0A55.812,55.812,0,0,1,0,9.02"
                        transform="translate(54.701 37.583)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M0,0A56.155,56.155,0,0,0,18.382,8.234"
                        transform="translate(8.952 38.506)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M0,0H82V82H0Z"
                        transform="translate(82 82) rotate(180)"
                        fill="none"
                        opacity="0"
                    />
                </svg>
            ),
            alt: "briefcase timer icon",
            heading: "TIME",
            description: "At 24H  as weekly",
        },
        {
            key: 2,
            /* 
            TODO: fix icon at LocalLang.js file
            */
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="82"
                    height="82"
                    viewBox="0 0 82 82"
                >
                    <path
                        d="M0,20.56V37.541c0,6.218,0,6.218,5.877,10.182L22.038,57.05a9.77,9.77,0,0,0,8.815,0l16.161-9.328c5.877-3.963,5.877-3.963,5.877-10.182V20.56c0-6.218,0-6.218-5.877-10.182L30.852,1.051a9.77,9.77,0,0,0-8.815,0L5.877,10.378C0,14.341,0,14.341,0,20.56Z"
                        transform="translate(14.555 17.092)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M37.583,19.236V10.25Q37.583,0,27.333,0H10.25Q0,0,0,10.25V19"
                        transform="translate(22.208 6.833)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M13.369,1.384l1.947,3.041a2.976,2.976,0,0,0,1.5,1.093l3.485.888c2.153.547,2.733,2.392,1.333,4.1l-2.289,2.768a3.066,3.066,0,0,0-.581,1.777l.205,3.588c.137,2.221-1.435,3.348-3.485,2.528l-3.348-1.333a2.948,2.948,0,0,0-1.879,0L6.912,21.166c-2.05.82-3.622-.342-3.485-2.528l.2-3.588a2.989,2.989,0,0,0-.581-1.777L.762,10.506c-1.4-1.708-.82-3.553,1.333-4.1l3.485-.888a2.889,2.889,0,0,0,1.5-1.093L9.03,1.384C10.26-.461,12.174-.461,13.369,1.384Z"
                        transform="translate(29.783 36.165)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M0,0H82V82H0Z"
                        transform="translate(82 82) rotate(180)"
                        fill="none"
                        opacity="0"
                    />
                </svg>
            ),
            alt: "award icon",
            heading: "PREMIUM",
            description: "products with Quality",
        },
    ];
    const social = [
        {
            key: 1,
            icon: Facebook,
            to: "https://facebook.com",
            title: "Facebook",
        },
        {
            key: 2,
            icon: Instagram,
            to: "https://instagram.com",
            title: "Instagram",
        },
        {
            key: 3,
            icon: Twitter,
            to: "https://twitter.com",
            title: "Twitter",
        },
        {
            key: 4,
            icon: arrowDown,
            to: "#serves",
            title: "Down",
        },
    ];

    return (
        <>
            <div id="Home"></div>
            <div className="f-i-Down home animate__animated">
                <div className="container">
                    <ul className="f-i-right social-media animate__animated">
                        {social.map((e) => (
                            <li
                                className="f-i-Down animate__animated"
                                title={e.title}
                                key={e.key}
                            >
                                <a href={e.to}>
                                    <img src={e.icon} alt={e.title} />
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className="heading">
                        <h1 className="f-i-up animate__animated">
                            {data.heading}
                        </h1>
                        <p className="f-i-up animate__animated">
                            {data.description}
                        </p>
                    </div>
                    <div className="f-i-up box animate__animated">
                        <div className="cards">
                            <div className="overlay-blur"></div>
                            <h3 className="f-i-up animate__animated">
                                {data.boxText}
                            </h3>
                            <div className="cards-box">
                                {box.map((e) => (
                                    <div
                                        key={e.key}
                                        className="f-i-up card animate__animated"
                                    >
                                        {/* <img
                                            className="f-i-up animate__animated"
                                            src=
                                            alt={e.alt}
                                        /> */}
                                        {e.icon}
                                        <h6 className="f-i-up animate__animated">
                                            {e.heading}
                                        </h6>
                                        <span className="f-i-up animate__animated">
                                            {e.description}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Home;
