const Theme = () => {
    const theme = [
        {
            Id: 1,
            value: "dark",
            textContent: "Dark",
        },
        {
            Id: 2,
            value: "light",
            textContent: "Light",
        },
        {
            Id: 3,
            value: "orange",
            textContent: "Orange",
        },
        {
            Id: 4,
            value: "darkBlue",
            textContent: "Dark Blue",
        },
    ];
    return (
        <>
            <ul className="ulTheme">
                {theme.map((th) => (
                    <li
                        key={th.Id}
                        value={th.value}
                        onClick={() => {
                            document.body.id = th.value;
                        }}
                    >
                        {th.textContent}
                    </li>
                ))}
            </ul>
        </>
    );
};
export default Theme;
