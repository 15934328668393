const EventContent = {
    heading: "WELCOME TO ",
    EventName: "Event Name",
    EventDescription: "Event Description Lorem ipsam",
    Reserve: "Reserve",
    Date: new Date("October 25, 1982 09:20:50"),
};
var theDate = new Date("October 25, 1982 09:20:50");
console.log(theDate);
const EventHome = () => {
    return (
        <div className="event-page_home">
            <div className="heading">
                <h1>{EventContent.heading}</h1>
                <h3>{EventContent.EventName}</h3>
            </div>
            <div className="box">
                <div className="box_overlay-blur"></div>
                <div className="event-page_content-box">
                    <div className="text">
                        <h4>{EventContent.EventName}</h4>
                        <h6>{EventContent.EventDescription}</h6>
                    </div>

                    <button>{EventContent.Reserve}</button>

                    <div className="date">
                        <div className="remaining">
                            <p>1 DAY 13:05:22 pm </p>
                        </div>
                        <div className="show-date">
                            <h1>{new Date().getFullYear()}</h1>
                            <h2>03/0{new Date().getMonth()}</h2>
                            <h1>
                                {new Date().getHours()}
                                <span>pm</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EventHome;
