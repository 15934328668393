import Slide from "./Slide";
import { TargetElements } from "./../../Functions/TargetElement";

const Event = () => {
    TargetElements(".event-f-i-up", "animate__fadeInUp");
    return (
        <>
            <div id="Event"></div>
            <div className="Event">
                <h1 className="animate__animated event-f-i-up">Events</h1>
                <div className="container">
                    <Slide />
                </div>
            </div>
        </>
    );
};
export default Event;
